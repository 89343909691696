.navbar{
  padding: 8px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: none!important;
  // border: 1px solid rgba(0,0,0,.0625);
  border: 0;
}

.nav-icon{
  margin-right: 20px;
  display: inline-block;
  font-size: 17px;
  color: #666;
  display: none;
  margin-left: 20%;
  transition: .3s;  
}

.nav-collapse {
  margin-left: 4.5%;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,.5);
  border-radius: 25px;
  padding: 5px 20px;
  font-size: 13px;
  text-transform: capitalize;
}

.dropdown-menu {
  left: auto;
  right: 0;
  padding: .3rem 0;
  font-size: 13px;
}

.dropdown-item{
  color: #72777a!important
}

.dropdown-toggle:after{
  border: none;
}