// @import '../animation_mixin.scss';

// @include keyframes(fadeIn) {
//   from{
//       opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// } 
// @include keyframes(fadeOut) {
//   from{
//       opacity: 1;
//   }
//   to {
//     opacity: 0;
//   }
// } 

// // defining mixin
// @mixin fadeIn {
//   @include animate(fadeIn, 1s, 1, forwards, linear);
// }
// @mixin fadeOut {
//   @include animate(fadeOut, 1s, 1, forwards, linear);
// }

$sidebar_base_color: #3876ff;
$sidebar_gradient_color:  #62f0ff;
$hover_color: #3876ff;
$text_color: #6d9bff;
// .sidebar {
//   background-color: #fff;
//   bottom: 0;
//   overflow: hidden;
//   position: fixed;
//   top: 45px;
//   -webkit-transition: all .2s ease;
//   -o-transition: all .2s ease;
//   transition: all .2s ease;
//   width: 15%;
//   z-index: 100;
//   // padding-top: 45px;
// }

.sidebar-logo img {
  height: 35px;
  margin: 0 10px;
  border-radius: 10px;
}

.sidebar ul {
  list-style-type: none
}

.logo-label {
  font-weight: 400;
  white-space: nowrap;
}

.sidebar-inner {
  position: fixed;
  height: 100%;
  top: 45px;
  left: 0;
  width: 15%;
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
  background: #fff;
}

.sidebar-logo {
  // border-bottom: 1px solid rgba(0, 0, 0, .0625);
  // border-right: 1px solid rgba(0, 0, 0, .0625);
  margin-bottom: 5px;
  line-height: 0;
  padding: 9px 20px;
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease
}

.sidebar-logo a .logo {
  background-position: 0;
  background-repeat: no-repeat;
  display: inline-block;
  min-height: 64px;
  width: 100%;
  width: 70px
}

.sidebar-logo a .logo-text {
  color: #313435
}

.sidebar-logo .mobile-toggle {
  display: none;
  float: right;
  font-size: 18px;
  line-height: 64px
}

.sidebar-logo .mobile-toggle a {
  color: #72777a
}

.sidebar-menu {
  // border-right: 1px solid rgba(0, 0, 0, .0625);
  height: calc(100vh - 65px);
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;

  &:hover {
    overflow: auto;
  }
}


.sidebar-menu:after {
  display: block;
  clear: both;
  content: ""
}

.sidebar-menu .dropdown-toggle:after {
  display: none
}

.sidebar-menu .sidebar-link.active:before {
  // background: #2196f3;
  border-radius: 50%;
  content: "";
  display: block;
  height: 8px;
  left: -4px;
  position: absolute;
  top: calc(50% - 4px);
  width: 8px
}

.sidebar-menu li {
  position: relative
}

.sidebar-link {
  text-align: center;

  &:hover .title,
  &:hover i {
    color: $text_color;
  }
}

.nav-item.active {
  .icon-holder {
    background: #f9f9f9;

    i {
      background: linear-gradient(45deg, $sidebar_base_color, $sidebar_gradient_color);
      padding: 7px;
      border-radius: 12px;
      color: #fff;
      width: 30px;
      height: 28px;
    }
  }

  .title {
    color: $text_color;
    font-weight: bold
  }
}

.title {
  font-size: 12px;
  margin-top: 5px;
  color: #aaa;
}

.logo-row {
  align-items: center;
  // justify-content: center;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
}

.sidebar-menu li.dropdown .arrow {
  font-size: 10px;
  line-height: 40px;
  position: absolute;
  right: 30px;
  -webkit-transition: all .05s ease-in;
  -o-transition: all .05s ease-in;
  transition: all .05s ease-in
}

// .sidebar-menu li.dropdown.open>a {
//   color: #313435
// }

// .sidebar-menu li.dropdown.open>a .icon-holder {
//   color: #0f9aee
// }

// .sidebar-menu li.dropdown.open>a>.arrow {
//   -webkit-transform: rotate(90deg);
//   -ms-transform: rotate(90deg);
//   transform: rotate(90deg)
// }

// .sidebar-menu li.dropdown.open>.dropdown-menu {
//   display: block
// }

// .sidebar-menu li.dropdown.open>.dropdown-menu .dropdown-menu {
//   padding-left: 20px
// }

// .sidebar-menu li.dropdown.open>.dropdown-menu .arrow {
//   line-height: 25px
// }

.sidebar-menu li a {
  color: #72777a;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}


.sidebar-menu>li.dropdown ul.dropdown-menu {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  float: none;
  padding-left: 50px;
  padding-top: 0;
  position: relative;
  width: 100%
}

.sidebar-menu>li.dropdown ul.dropdown-menu>li>a {
  display: block;
  padding: 10px 15px
}

.sidebar-menu>li.dropdown ul.dropdown-menu>li>a:focus,
.sidebar-menu>li.dropdown ul.dropdown-menu>li>a:hover {
  background-color: transparent;
  color: #313435;
}

.sidebar-menu>li.dropdown ul.dropdown-menu>li.active a {
  color: #313435
}

.sidebar-menu>li>a {
  display: block;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 15px;
  position: relative;
  white-space: nowrap
}

.sidebar-menu>li>a .icon-holder {
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 65px;
  width: 65px;
  left: 0;
  line-height: 35px;
  margin: 0 auto 5px;
  position: relative;
  text-align: center;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  color: #ccc;
}

.is-collapsed.sidebar {
  width: 4.5%;

  .logo-label {
    margin-left: 10px;
  }

  .sidebar-logo img {
    margin: 0;
    margin-left: 8px;
  }

  &:hover {
    width: 19.1%;
  }
}

/* width */
.sidebar-menu::-webkit-scrollbar {
  width: 0px;
}


@media only screen and (max-width: 600px) {
  .sidebar-inner {
    width: 60%;
    left: -60%;
    transition: .3s;
    z-index: 1000;

    &.show {
      left: 0;
      box-shadow: 5px 25px 30px rgba(0,0,0,.09);

      .sidebar-menu {
        overflow: auto;
      }
    }

  }
}