
// Fonts
// @import url('https://fonts.googleapis.com/css?family=Roboto');
// @import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Muli:400,600,700,900&display=swap');
// Variables
@import "variables";
@import "./components/navbar.scss";
@import "./components/sidebar.scss";
// Bootstrap
// @import '~bootstrap/scss/bootstrap';
body{
  background: #fff;
  font-size: 14px;
  color: #72777a;
  line-height: 1.5;
  // font-family: 'Nunito', sans-serif;
  font-family: 'Muli', sans-serif;
  width: 100%;
}
.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.btn{
  font-weight: 300;
  border-radius: 5px;
}

.common-rad{
  border-radius: 25px;
}

.table thead th {
  border-bottom: 0;
}

.form-control{
  border-color: #e2e2e2;
  border-radius: 25px;
}

select.form-control {
  -webkit-appearance: none;
}

.form-label {
  color: #72777a;
}

.lead {
  font-size: 18px
}

ul {
  margin-bottom: 0
}

a {
  color: #0f9aee
}

a:focus,
a:hover {
  text-decoration: none;
  color: #0c7bbe
}

a:focus {
  outline: none
}

a.text-gray.active,
a.text-gray:focus,
a.text-gray:hover {
  color: #313435 !important
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: .5px;
  color: #313435;
}

:focus {
  outline: none
}

hr {
  border-top: 1px solid rgba(0, 0, 0, .0625)
}

.m-30 {
  margin: 30px !important
}

.mT-30 {
  margin-top: 30px !important
}

.mR-30 {
  margin-right: 30px !important
}

.mB-30 {
  margin-bottom: 30px !important
}

.mL-30 {
  margin-left: 30px !important
}

.mY-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important
}

.mX-30 {
  margin-left: 30px !important;
  margin-right: 30px !important
}

.pT-30 {
  padding-top: 30px !important;
}

.mB-50 {
  margin-bottom: 50px !important
}

.word-break {
  word-break: break-all;
}

.border-grey {
  border: 1px solid #e2e2e2 !important;
}

.height-auto {
  height: auto !important;
}

.main-content {
  padding: 35px 75px;
  min-height: calc(100vh - 45px);
  border-radius: 50px 25px 25px 25px;
  width: 85%;
  background: #f9f9f9;
  // position: fixed;
  float: right;
  margin-top: 55px;
  transition: .3s;
  overflow: auto;
}

.list-status {
  position: relative;
  &:before  {
    position: absolute;
    height: 5px;
    width: 5px;
    font-size: 20px;
    border-radius: 50%;
    left: -12px;
    top: 4px;
    color: inherit;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f192";
    font-size: 8px;
  }
}

.white-space-normal {
  white-space: normal !important;
}

.white-space-nowrap {
  white-space: nowrap!important;
}

.section-title {
  margin-bottom: 30px;
  position: relative;
  display: inline-block;
}

.title-shadow {
  position: absolute;
  top: 4px;
  left: 10px;
  color: #717171;
  z-index: 1;
  font-size: 55px;
  opacity: .04;
  white-space: nowrap;
}

.main-expand {
  width: 95.5%;
}

.nav-link{
  position: relative;
  font-weight: bold;
}

.card-shadow {
  border: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 10px 15px;
  z-index: 2;
  width: 100%;
  position: relative;
}

.card-round {
  border-radius: 25px;
  overflow: hidden;
}

.merge-top {
  margin-top: -50px;
}

i {
  pointer-events: none;
}

.bg-primary {
  background: linear-gradient(45deg, #2164f9, #11d9ef);
  opacity: .9;
  &.card-shadow {
    box-shadow: 5px 5px 5px #007bffa1;
  }
}

.btn-primary {
  // background: linear-gradient(45deg, #034ff7, #1babff);
  background: linear-gradient(45deg, #2164f9, #11d9ef);
  border: 0;
}

.btn-success {
  // background: linear-gradient(45deg, #009688, #4CAF50);
  background: linear-gradient(45deg, #0c9a52, #4fd892);
  border: 0;
}

.btn-danger {
  // background: linear-gradient(45deg, #dc3545, #ff864c);
  background: linear-gradient(45deg, #ff5a6a, #ff864c);
  border: 0;
}

.btn-info {
  background: linear-gradient(45deg, #00bfdc, #00ace0);
  border: 0;
}

.btn-round {
  border-radius: 25px !important;
  padding: 6px 15px;
}

.btn-round-sm {
  border-radius: 25px !important;
  padding: 5px 10px;
}

.btn-pagination {
  padding: 6px 15px;
}

.btn-round-right{
  @extend .btn-pagination;
  border-radius: 0 25px 25px 0 !important;
}

.btn-round-left{
  @extend .btn-pagination;
  border-radius: 25px 0 0 25px !important;
}

.input-round-left {
  border-radius: 25px 0 0 25px !important;
}

.table-bordered, .table-bordered td, .table-bordered th {
  border: 1px solid #eee;
}

.table-scroll {
  max-height: 400px;
  overflow: auto;
}

.horizontal-scroll {
  white-space: nowrap;
  overflow: auto;
}

.table-bordered {
  border-radius: 25px;
  overflow: hidden;

  thead {
    background: #f5f5f5;
  }

  tbody td {
    border: 0;
    padding: 15px 24px;
  }
}

.table-hover tbody tr:hover {
  background-color: #f8f9fa!important;
  cursor: pointer;
}

.form-group{
  margin-bottom: 1.8rem;
}

.dropdown-shadow {
  border-radius: 15px;
  border: 0;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  padding: 15px 10px;
}
// placeholders
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #ccc!important;
  font-size: 12px;
  font-weight: 100;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #ccc!important;
  font-size: 12px;
  font-weight: 100;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #ccc!important;
  font-size: 12px;
  font-weight: 100;
}
:-moz-placeholder { /* Firefox 18- */
  color: #ccc!important;
  font-size: 12px;
  font-weight: 100;
}

.pointer-none{
  pointer-events: none;
}

.common-radius {
  border-radius: 25px;
}

.red-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: linear-gradient(45deg, #dc3545, #ff864c);
  position: absolute;
  top: 0px;
  right: 0px;
}

.no-focus {
  &:focus {
    box-shadow: none;
  }
}

.nav-container {
  display: inline-block;
  position: relative;

  .nav-item {
    display: inline-block
  }

  .nav-link {
    border-radius: 25px;

    &.active {
      background-image: linear-gradient(45deg, #3876ff, #11d9ef);
    }
  }

}

.fullscreen_mode {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  max-width: 100%;
  overflow: scroll;
  height: 100vh;
}

.btn-fullscreen{
  position: absolute;
  right: 10px;
  top: 10px;
  outline: none;

  &:focus {
    box-shadow: none;
  }
}

.pretty input:checked~.state.p-primary label:after, .pretty.p-toggle .state.p-primary label:after {
  background-color: #007bff!important;
}

.pretty input:checked~.state.p-primary-o label:before, .pretty.p-toggle .state.p-primary-o label:before {
  border-color: #007bff!important;
}

.pretty.p-default:not(.p-fill) input:checked~.state.p-primary-o label:after {
  background-color: #007bff!important;
}

.pretty.p-switch.p-fill input:checked~.state.p-success:before {
  background: linear-gradient(45deg, #00c3b1, #4CAF50)!important;
}

.rounded-left-0 {
  border-top-left-radius: 0px !important; 
  border-bottom-left-radius: 0px !important;
}

.rounded-right-0 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.custom-width{
  width: 49%;
  display: inline-block;
}

.choices__inner {
  border-radius: 25px!important;
  background: #fff!important;
  padding: 4.5px 7.5px 0.75px!important;
  min-height: 35px!important;
}

.choices__list--multiple .choices__item {
  background-color: #007bff!important;
  border-color: #007bff!important;
}

.choices__input {
  background-color: #fff!important;
}

.choices {
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .main-content {
    width: 100%;
    padding: 25px 0;
    border-radius: 25px 25px 0 0;
  }

  // .main-wrapper {
  //   left: 0;
  //   right: 0;
  // }

  .navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    border-color: rgba(0,0,0,.1);
    margin-left: auto;
    border: 0;
    font-size: 14px;
  }
}